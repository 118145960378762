import StrategyService, { IndicatorModel } from '/src/services/strategies'

interface IndicatorsCache {
	[key: string]: IndicatorModel
}

let allIndicators: IndicatorsCache;

export interface IndicatorGroup {
	name: string,
	indicators: IndicatorModel[]
}

export const IndicatorsCache = {
	initialize: async () => {
		if (allIndicators === undefined) {
			const response = await StrategyService.getIndicators();
			allIndicators = response.reduce((a, c) => { a[c.name] = c; return a; }, {});
		}
	},

	getIndicator: (name: string): IndicatorModel => {
		return allIndicators[name];
	},

	searchIndicatorsByGroup: (groups: string[]): IndicatorModel[] => {
		return Object.keys(allIndicators).map(fm => {
			if (allIndicators[fm].tags.indexOf("system") !== -1) {
				return undefined;
			}
			return allIndicators[fm];
		}).filter(r => r !== undefined).filter(i => groups.includes(i.group));
	},

	searchIndicators: (search: string): IndicatorGroup[] => {
		var grouped = Object.keys(allIndicators).map(fm => {
			if (allIndicators[fm].tags.indexOf("system") !== -1) {
				return undefined;
			}
			if (allIndicators[fm].type != "DecimalStream") {
				return undefined;
			}
			if (search.length > 0 &&
				fm.substring(0, search.length).toUpperCase() !== search.toUpperCase()) {
				return undefined;
			}
			return allIndicators[fm];
		}).filter(r => r !== undefined).reduce((group, ind) => {
			(group[ind.group] = group[ind.group] || []).push(ind);
			return group;
		}, {})
		return Object.keys(grouped).map(groupName => {
			return {
				name: groupName,
				indicators: grouped[groupName]
			}
		});
	}
}