import * as React from "react";
import { Column, isDesktop, Row } from '/src/components/Layout'
import { FunctionCall, IToken, LineModel, SectionModel, TradeRuleToken } from "./EditorModels";
import TokenText from "./TokenText";
import { ChartTimeItem } from "/src/services/strategies";
import BooleanChartItem from "./BooleanChartItem";

interface Props {
	token: TradeRuleToken,
	onSelect: (codeLine: IToken) => void,
	isSelected: boolean,
	metaId: number,
	editable: boolean,
	metaSubscribe: (eventType, subscriberId, metaListener) => void,
	triggerMetaEvent: (eventType, senderId, eventData) => void,
	chartData: ChartTimeItem[],
	onHover: (currentItem: ChartTimeItem) => void,
}

const TradeSection: React.FunctionComponent<Props> = ({ token, onSelect, isSelected, editable, metaId, metaSubscribe, triggerMetaEvent, chartData, onHover }) => {

	const style = isSelected ? "bg-button" : "";

	return <Column>
		<Row className={isDesktop ? "" : "mleft15"}>
			<Row crossAxis="center" className={"border mbottom7 " + style + " pointer"}
				onClick={() => onSelect(token)}
				onMouseDown={(e) => { /*do not do unselecting when other line is selected*/ e.stopPropagation(); }}
				style={{ height: 35, borderRadius: 17, paddingLeft: 20, paddingRight: 20 }}>

				<Row className="font18 bold">
					{token.direction}&nbsp;<span className="bold">{token.instrument.symbol.substring(1)}</span>
				</Row>

			</Row>
		</Row>

		{token.openConditions.map((condition, indexInSection) =>
			<Column key={"open" + indexInSection}>
				<Row mainAxis="flex-end" className="mright60"><TokenText key={"codeline_" + indexInSection} token={condition} /></Row>
				<BooleanChartItem
					hasXAxis={indexInSection === token.openConditions.length - 1}
					metaId={"chart_open_" + metaId + "_" + indexInSection}
					chartContext={{ metaSubscribe: metaSubscribe, triggerMetaEvent: triggerMetaEvent }}
					data={chartData}
					token={condition}
					onHover={onHover}
				/>
			</Column>
		)}
		{token.closeConditions.map((condition, indexInSection) =>
			<Column key={"close" + indexInSection}>
				<Row mainAxis="flex-end" className="mright60"><TokenText key={"codeline_" + indexInSection} token={condition} /></Row>
				<BooleanChartItem
					hasXAxis={indexInSection === token.closeConditions.length - 1}
					metaId={"chart_close_" + metaId + "_" + indexInSection}
					chartContext={{ metaSubscribe: metaSubscribe, triggerMetaEvent: triggerMetaEvent }}
					data={chartData}
					token={condition}
					onHover={onHover}
				/>
			</Column>
		)}
	</Column>

}


export default TradeSection;