import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import { Link } from "react-router-dom";
import StrategyService, { ChartTimeItem, IndicatorModel, SignalDirection, StrategyInfo, StrategyState, StrategyStatement, TestStatus, TimeValue } from '/src/services/strategies'
import { FormattedMessage, FormattedDate } from 'react-intl';
import { StrategyResponse } from '/src/services/strategies'
import EditorHeader from "./EditorHeader";
import TradingService, { Position, DealStats, PositionStatus } from "/src/services/trading"
import { ISelectable, LineModel, TradeRuleToken, Direction, StrategyTab, IToken, TokenType, IndicatorToken, InstrumentToken } from './EditorModels'
import SidePanel, { PanelType } from "./Panel/SidePanel";
import { Ticker } from '/src/services/trading';
import { PublicUserModel } from "/src/services/users";
import { niceNumber, signedPercent } from "/src/utils/numbers";
import 'react-circular-progressbar/dist/styles.css';
import StatsTab from "./StatsTab";
import { Transaction } from "/src/services/wallets";
import MobilePanel from "./Panel/MobilePanel";
import PlusButton from "./PlusButton";
import FloatingTestButton from "./FloatingTestButton";
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Slide, ToastContainer } from "react-toastify/dist";
import Plot from "./Plot";
import DesktopNavigationBar from "/src/components/DesktopNavigationBar";
import Button from "/src/components/Button";
import PositionsPanel from "./Panel/PositionsPanel";
import Positions from "./Panel/Positions";

export interface TokenCallbacks {
	onAdded: (token: IToken) => void
	onUpdated: (token: IToken) => void
	onDeleted: (token: IToken) => void,
	onMovedUp: (token: IToken) => void,
	onMovedDown: (token: IToken) => void,
}

interface Props {
	author: PublicUserModel,
	demoStatement?: StrategyStatement,
	strategy: StrategyInfo,
	investmentStatement?: StrategyStatement,
	tokens: IToken[],
	charts: ChartTimeItem[],
	positions: Position[],
	morePositions: boolean,
	dealsStats: DealStats,
	ownStrategy: boolean,
	history: any,
	hasTradeRules: boolean,
	hasInstruments: boolean,
	onStrategyNameChanged: (name: string) => void,
	onStrategyDescriptionChanged: (description: string) => void,
	tokenCallbacks: TokenCallbacks
	onBacktest: () => void,
	backtestStatus: TestStatus,
	initialTab: StrategyTab,
	onInvest: (amount: number) => void,
	onStopInvest: () => void,
	transactions: Transaction[],
	onStrategyDelete: () => void
	onSignalForced: (ruleIndex: number, direction: SignalDirection) => void,
	onLoadMorePositions: () => void
}

interface State {
	tab: StrategyTab,
	sidePanelType: PanelType,
	selected: ISelectable,
	currentName: string,
	currentDescription: string
}

let requestedMorePositions = false;

class StrategyEditor extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			tab: props.initialTab,
			sidePanelType: props.initialTab === StrategyTab.Stats ? PanelType.Common : PanelType.Default,
			selected: undefined,
			currentName: (props.strategy ? props.strategy.name : "---"),
			currentDescription: (props.strategy ? props.strategy.description : ""),
			loadingMore: false
		};
	}

	componentWillReceiveProps(nextProps: Props) {
		requestedMorePositions = false;
		this.setState({
			currentName: (nextProps.strategy ? nextProps.strategy.name : "---"),
			currentDescription: (nextProps.strategy ? nextProps.strategy.description : ""),
			loadingMore: false
		});
	}

	onAdded = (token: IToken) => {
		this.setState({ sidePanelType: PanelType.Default, selected: token }
			, () => { this.props.tokenCallbacks.onAdded(token) });
	}

	onUpdated = (token: IToken) => {
		this.setState({ sidePanelType: PanelType.Default, selected: token }
			, () => { this.props.tokenCallbacks.onUpdated(token) });
	}

	onSignalForced = (direction: SignalDirection) => {
		this.props.onSignalForced(/*(this.state.selected as IToken).position*/ 0, direction)
	}

	onDeleted = (token: IToken) => {
		this.setState({ sidePanelType: PanelType.Default, selected: undefined }
			, () => { this.props.tokenCallbacks.onDeleted(token) });
	}

	onInstrumentEdit = (token: IToken) => {
		this.setState({ sidePanelType: PanelType.NewInstrument, selected: token });
	}

	onIndicatorEdit = (token: IToken) => {
		this.setState({ sidePanelType: PanelType.NewIndicator, selected: token });
	}

	tokenUsesName = (token: IToken, name: string) => {
		if (token.type == TokenType.Indicator) {
			const indicatorToken = token as IndicatorToken;
			if (indicatorToken.parameters.includes(name)) {
				return true;
			}
		}
		if (token.type == TokenType.TradeRule) {
			const tradeRuleToken = token as TradeRuleToken;
			if (tradeRuleToken.openConditions.some(c => c.parameters.includes(name))
				|| tradeRuleToken.closeConditions.some(c => c.parameters.includes(name))) {
				return true;
			}
		}
		return false;
	}

	canBeMovedUp = (token: IToken) =>
		token
		&& token.type !== undefined
		&& token.position > 0
		&& !this.tokenUsesName(token, this.props.tokens[token.position - 1].name)

	canBeMovedDown = (token: IToken) =>
		token
		&& (token.type !== undefined)
		&& (token.position < this.props.tokens.length - 1)
		&& !this.tokenUsesName(this.props.tokens[token.position + 1], token.name)

	canBeDeleted = (token: IToken) =>
		token
		&& token.type !== undefined
		&& !this.props.tokens.slice(token.position + 1).some(t => this.tokenUsesName(t, token.name))

	onMovedUp = (token: IToken) => {
		this.props.tokenCallbacks.onMovedUp(token);
		this.setState({ selected: token });
	}

	onMovedDown = (token: IToken) => {
		this.props.tokenCallbacks.onMovedDown(token);
		this.setState({ selected: token });
	}

	onUnselect = () => {
		if (this.state.tab === StrategyTab.Rules) {
			this.setState({ sidePanelType: PanelType.Default, selected: undefined });
		}
	}

	onSelect = (token: IToken) => {
		this.setState({ selected: token });
	}

	onPanelClosed = () => {
		if (this.state.tab === StrategyTab.Rules) {
			this.setState({ sidePanelType: PanelType.Default, selected: undefined });
		}
		else {
			this.setState({ sidePanelType: PanelType.Common, selected: undefined });
		}
	}

	getInstrumentOptions = (): InstrumentToken[] =>
		this.props.tokens.filter((l, i) =>
			l.name
			&& l.type == TokenType.Instrument
			&& (this.state.selected ? i < (this.state.selected as IToken).position : true)).map(t => t as InstrumentToken);

	onTradeRuleAdding = (): TradeRuleToken => {
		const instruments = this.props.tokens.filter((l, i) =>
			l.type === TokenType.Instrument
			&& (this.state.selected ? i < (this.state.selected as IToken).position : true));

		if (instruments.length === 0) {
			return undefined;
		}

		const lastInstrument = (instruments[instruments.length - 1] as InstrumentToken);

		const instrument: Ticker = {
			symbol: ":" + lastInstrument.symbol,
			exchange: lastInstrument.exchange
		}

		const defaultTradeRule: TradeRuleToken = {
			direction: Direction.Buy,
			instrument: instrument,
			fundsPerTrade: 1,
			maxOpenTrades: 1,
			stopLossPercent: 1,
			takeProfitPercent: 1,
			openConditions: [],
			closeConditions: [],
			name: undefined,
			type: TokenType.TradeRule,
			dataIndex: undefined,
			position: undefined
		};

		this.onAdded(defaultTradeRule);
	}

	countDays = (values: TimeValue[]) => {
		if (values.length === 0) {
			return 0;
		}
		const timespan = values[values.length - 1].timestamp - values[0].timestamp;
		const days = Math.round(timespan / (1000 * 60 * 60 * 24));
		return days;
	}

	onTabChanged = (newtab: StrategyTab) => {
		if (newtab == StrategyTab.Stats) {
			window.history.replaceState(null, "", '/strategy/' + this.props.strategy.id)
		}
		if (newtab == StrategyTab.Rules) {
			window.history.replaceState(null, "", '/strategy/' + this.props.strategy.id + '/rules')
		}
		if (newtab == StrategyTab.Positions) {
			window.history.replaceState(null, "", '/strategy/' + this.props.strategy.id + '/positions')
		}
		this.setState({
			tab: newtab,
			sidePanelType: newtab === StrategyTab.Stats ? PanelType.Common : PanelType.Default,
			selected: undefined
		})
	}

	onBacktest = () => {
		window.history.replaceState(null, "", '/strategy/' + this.props.strategy.id)
		this.setState({ tab: StrategyTab.Stats, sidePanelType: PanelType.Common })
		this.props.onBacktest();
	}

	onTradeSelected = (trade: Position) => {
		this.setState({ sidePanelType: PanelType.Trade, selected: trade })
	}

	onInvesting = () => {
		this.setState({ sidePanelType: PanelType.Invest, selected: undefined })
	}

	onStopInvesting = () => {
		this.props.onStopInvest();
	}

	onInvest = (amount: number) => {
		this.props.onInvest(amount);
		this.setState({ sidePanelType: PanelType.Common, selected: undefined })
	}

	render() {
		const allOptions = this.props.tokens.filter((l, i) => (l.type != TokenType.TradeRule) && (this.state.selected ? i < (this.state.selected as IToken).position : true));
		const movingCallbacks = {
			canBeMovedUp: this.canBeMovedUp(this.state.selected as IToken),
			canBeMovedDown: this.canBeMovedDown(this.state.selected as IToken),
			canBeDeleted: this.canBeDeleted(this.state.selected as IToken),
			onMovedUp: this.onMovedUp,
			onMovedDown: this.onMovedDown,
			onDeleted: this.onDeleted,
			onInstrumentAdding: () => {
				if (this.state.sidePanelType === PanelType.Default) {
					this.setState({ selected: undefined, sidePanelType: PanelType.NewInstrument })
					return true;
				}
				return false;
			},
			onIndicatorAdding: () => {
				if (this.state.sidePanelType === PanelType.Default) {
					this.setState({ selected: undefined, sidePanelType: PanelType.NewIndicator })
					return true;
				}
				return false;
			},
			onTradeRuleAdding: () => {
				if (this.state.sidePanelType === PanelType.Default) {
					this.onTradeRuleAdding()
					return true;
				}
				return false;
			}
		};

		const editingCallbacks = {
			onInstrumentAdding: () => this.setState({ selected: undefined, sidePanelType: PanelType.NewInstrument }),
			onInstrumentAdded: this.onAdded,
			onInstrumentEdit: this.onInstrumentEdit,
			onInstrumentUpdated: this.onUpdated,
			onIndicatorSelected: this.onAdded,
			onIndicatorAdding: () => this.setState({ selected: undefined, sidePanelType: PanelType.NewIndicator }),
			onIndicatorUpdated: this.onUpdated,
			onIndicatorEdit: this.onIndicatorEdit,
			onTradeRuleAdding: this.onTradeRuleAdding,
			onTradeRuleUpdated: this.onUpdated,
		}

		const handleScroll = event => {
			const loaderHeight = 80;
			const reachedEnd = Math.abs(event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight) < loaderHeight;
			if (!reachedEnd) {
				return;
			}
			if (this.state.tab === StrategyTab.Positions && !requestedMorePositions && this.props.morePositions) {
				requestedMorePositions = true;
				this.props.onLoadMorePositions();
			}
		};

		return isDesktop ? <Column>
			<Row>
				<Row onMouseDown={this.onUnselect} mainAxis="center" className="flex1 scroller" onScroll={handleScroll} style={{ height: "100vh", overflowY: "scroll" }}>
					<div style={{ marginLeft: 133 }} className="flex1 editor-width">
						{this.props.strategy && <EditorHeader
							isOwnStrategy={this.props.ownStrategy}
							onStrategyDelete={this.props.onStrategyDelete}
							onShowTrades={() => {/*button does not exist*/ }}
							onTabChanged={this.onTabChanged}
							tab={this.state.tab}
							strategy={this.props.strategy}
							onStrategyNameChanged={this.props.onStrategyNameChanged}
							history={this.props.history}
							author={this.props.author}
							currentName={this.props.strategy ? this.props.strategy.name : "---"}
						/>}

						{this.state.tab === StrategyTab.Stats &&
							<StatsTab {...this.props}
								onInvesting={this.onInvesting}
								onStopInvesting={this.onStopInvesting} />}
						{this.state.tab === StrategyTab.Rules && this.props.strategy &&
							<Plot
								allOptions={allOptions}
								onBacktest={this.onBacktest}
								hasTradeRules={this.props.hasTradeRules}
								strategyInfo={this.props.strategy}
								movingCallbacks={movingCallbacks}
								editingCallbacks={editingCallbacks}
								editable={this.props.investmentStatement === undefined}
								selected={this.state.selected}
								onSelect={this.onSelect}
								onUnselect={this.onUnselect}
								tokens={this.props.tokens}
								charts={this.props.charts}
								positions={this.props.positions} />}
						{this.state.tab === StrategyTab.Positions &&
							<Positions morePositions={this.props.morePositions} positions={this.props.positions} isDemoDeals={this.props.investmentStatement === undefined}/>
						}

						<ToastContainer
							limit={4}
							transition={Slide}
							position="bottom-center"
							autoClose={5000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover />
					</div>
				</Row>
				<SidePanel
					editingCallbacks={editingCallbacks}
					onSignalForced={this.onSignalForced}
					movingCallbacks={movingCallbacks}
					isOwnStrategy={this.props.ownStrategy}
					hasTradeRules={this.props.hasTradeRules}
					hasInstruments={this.props.hasInstruments}
					onInvest={this.onInvest}
					onInvesting={this.onInvesting}
					onStopInvesting={this.onStopInvesting}
					onTradeSelected={this.onTradeSelected}
					strategyState={this.props.strategy && this.props.strategy.state}
					allOptions={allOptions}
					instrumentOptions={this.getInstrumentOptions()}
					type={this.state.sidePanelType}
					selected={this.state.selected}
					onBacktest={this.onBacktest}
					onStrategyDelete={this.props.onStrategyDelete}
					onPanelClosed={this.onPanelClosed}
					positions={this.props.positions}
					morePositions={this.props.morePositions}
					onLoadMorePositions={this.props.onLoadMorePositions}
					isInvested={this.props.investmentStatement !== undefined}
					stats={
						this.props.investmentStatement ?
							this.props.investmentStatement.stats :
							this.props.demoStatement
								? this.props.demoStatement.stats : undefined} />
			</Row >
			<DesktopNavigationBar tab={-1} />
			{/*<MetaGrid />*/}
		</Column >

			:

			<Column onMouseDown={this.onUnselect} className="" style={{}}>
				{this.props.strategy && <EditorHeader
					isOwnStrategy={this.props.ownStrategy}
					onStrategyDelete={this.props.onStrategyDelete}
					onShowTrades={() => this.setState({ sidePanelType: PanelType.Trades })}
					onTabChanged={this.onTabChanged}
					tab={this.state.tab}
					strategy={this.props.strategy}
					onStrategyNameChanged={this.props.onStrategyNameChanged}
					history={this.props.history}
					author={this.props.author}
					currentName={this.props.strategy ? this.props.strategy.name : "---"}
				/>}

				{this.state.tab === StrategyTab.Stats && <StatsTab {...this.props} onInvesting={this.onInvesting}
					onStopInvesting={this.onStopInvesting} />}
				{this.state.tab === StrategyTab.Rules && this.props.strategy &&
					<Plot
						onBacktest={this.onBacktest}
						hasTradeRules={this.props.hasTradeRules}
						strategyInfo={this.props.strategy}
						movingCallbacks={movingCallbacks}
						editingCallbacks={editingCallbacks}
						editable={this.props.investmentStatement === undefined}
						selected={this.state.selected}
						onSelect={this.onSelect}
						onUnselect={this.onUnselect}
						tokens={this.props.tokens}
						charts={this.props.charts}
						positions={this.props.positions} />}
				{this.state.tab === StrategyTab.Rules
					&& this.props.strategy
					&& this.props.hasTradeRules
					&& this.props.strategy.state == StrategyState.Created
					&&
					<Row style={{ width: "100%", position: "fixed", bottom: 5 }} mainAxis="center">
						<div className="bg-inverted color-inverted font15 pointer"
							style={{ borderRadius: "5px", padding: "10px 20px" }}
							onClick={this.onBacktest}>
							RUN TEST
						</div>
					</Row>
				}
				{this.state.tab === StrategyTab.Positions &&
					<Positions morePositions={this.props.morePositions} positions={this.props.positions} />
				}
				<BottomSheet
					defaultSnap={({ maxHeight }) => maxHeight / 4}
					snapPoints={({ minHeight, maxHeight }) => [
						maxHeight * 0.85,
						maxHeight * 0.5
					]}
					expandOnContentDrag={true}
					initialFocusRef={false}
					onDismiss={e => {
						this.onPanelClosed();
					}}
					open={this.state.sidePanelType !== PanelType.Common &&
						!(this.state.sidePanelType === PanelType.Default && this.state.selected === undefined)}>
					<MobilePanel
						onInvest={this.onInvest}
						onInvesting={this.onInvesting}
						onStopInvesting={this.onStopInvesting}
						onTradeSelected={this.onTradeSelected}
						onStrategyDelete={this.props.onStrategyDelete}
						strategyState={this.props.strategy && this.props.strategy.state}
						allOptions={allOptions}
						isOwnStrategy={this.props.ownStrategy}
						hasTradeRules={this.props.hasTradeRules}
						hasInstruments={this.props.hasInstruments}
						instrumentOptions={this.getInstrumentOptions()}
						type={this.state.sidePanelType}
						selected={this.state.selected}
						editingCallbacks={editingCallbacks}
						movingCallbacks={movingCallbacks}
						onBacktest={this.onBacktest}
						onPanelClosed={this.onPanelClosed}
						deals={this.props.positions}
						isInvested={this.props.investmentStatement !== undefined}
						stats={
							this.props.investmentStatement ?
								this.props.investmentStatement.stats :
								this.props.demoStatement
									? this.props.demoStatement.stats : undefined} />
				</BottomSheet>
			</Column>
	}
}

export default StrategyEditor;