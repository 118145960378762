import * as React from "react";
import { isDesktop, Column, Row } from '/src/components/Layout'
import CodeLine from './Plot/CodeLine'
import ChartItem from './ChartItem'
import { ChartTimeItem } from '/src/services/strategies'
import { ChartMode, EditingCallbacks, IndicatorToken, InstrumentToken, IToken, LineModel, MovingCallbacks, TokenType } from "./EditorModels";

interface Props {
	selected: IToken,
	tokens: IToken[],
	allTokens: IToken[],
	chartData: ChartTimeItem[],
	onSelect: (token: IToken) => void,
	metaSubscribe: (eventType, subscriberId, metaListener) => void,
	triggerMetaEvent: (eventType, senderId, eventData) => void,
	metaId: number,
	editable: boolean,
	onHover: (currentItem: ChartTimeItem) => void,
	hoveredItem: ChartTimeItem,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks
}

const Section: React.FunctionComponent<Props> = props => {

	const getLastValue = (token: IToken, chartData: ChartTimeItem[]) => {

		if (props.hoveredItem && props.hoveredItem.values[token.dataIndex]) {
			return props.hoveredItem.values[token.dataIndex][3];
		}

		if (!chartData || token.dataIndex === undefined ||
			chartData.length === 0 || chartData[0].values === undefined ||
			chartData[0].values[token.dataIndex] === undefined) {
			return undefined;
		}
		for (let i = chartData.length - 1; i >= 0; i--) {
			if (chartData[i].values[token.dataIndex][3] !== undefined) {
				return chartData[i].values[token.dataIndex][3];
			}
		}
		return undefined;
	}

	return <Column>
		<Row className={isDesktop ? "" : "mleft15"} style={{ flexWrap: "wrap" }}>
			{props.tokens.map((token, indexInSection) =>
				<CodeLine
					editingCallbacks={props.editingCallbacks}
					movingCallbacks={props.movingCallbacks}
					options={props.allTokens}
					chartMode={token.type === TokenType.Indicator ? (token as IndicatorToken).chart : ChartMode.Own}
					editable={props.editable}
					key={"token" + indexInSection}
					isSelected={props.selected && (token.position === props.selected.position)}
					token={token}
					value={getLastValue(token, props.chartData)}
					onClick={() => props.onSelect(token)}
				/>
			)}
		</Row>
		<ChartItem
			selected={props.selected}
			instruments={props.tokens.filter(t => t.type === TokenType.Instrument).map(c => (c as InstrumentToken).symbol)}
			metaId={"chart" + props.metaId}
			chartContext={{ metaSubscribe: props.metaSubscribe, triggerMetaEvent: props.triggerMetaEvent }}
			data={props.chartData}
			tokens={props.tokens.filter(t => t.type !== TokenType.Indicator || (t as IndicatorToken).chart != ChartMode.Hidden)}
			onHover={props.onHover}
		/>
	</Column>
}

export default Section;