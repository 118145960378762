import * as React from "react";
import { ChartCanvas, Chart, series } from "react-stockcharts";
import { BarSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";
import { last } from "react-stockcharts/lib/utils";
import { fitWidth } from "react-stockcharts/lib/helper";
import { isMobile, Column, Row } from '/src/components/Layout'
import { LineModel, IndicatorToken } from './EditorModels';
import { timeFormat } from "d3-time-format";
import { niceNumber, niceSignedNumber } from '/src/utils/numbers';
import { ChartTimeItem } from '/src/services/strategies'
import { ThemeContext } from "/src/components/ThemeProvider";
import { ClickCallback } from "react-stockcharts/lib/interactive";

export interface ChartContext {
	metaSubscribe: (eventType: string, subscriberId: string, metaListener: (input: any) => void) => void,
	triggerMetaEvent: (eventType: string, senderId: string, eventData: any) => void
}

class BooleanChartItem extends React.Component<{
	type: any, width: any, ratio: any, data: ChartTimeItem[], token: IndicatorToken,
	metaId: number, paddingPercent: number, chartContext: ChartContext, hasXAxis: boolean, onHover: (currentItem: ChartTimeItem) => void
}> {

	onMouseEvent = (moreProps, event) => {
		if (moreProps && moreProps.currentItem) {
			this.props.onHover(moreProps.currentItem)
		}
	}

	render() {
		if (this.props.data.length < 2 || this.props.data[1].values.length <= this.props.token.dataIndex) {
			return <Row mainAxis="center">
				<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
			</Row>
		}

		const { type, width, ratio } = this.props;
		const xScaleProvider = discontinuousTimeScaleProvider
			.inputDateAccessor((d: ChartTimeItem) => new Date(d.timestamp));
		const {
			data,
			xScale,
			xAccessor,
			displayXAccessor,
		} = xScaleProvider(this.props.data);

		const start = xAccessor(last(data));
		const end = xAccessor(data[Math.max(0, data.length - (isMobile ? 40 : 120))]);
		const xExtents = [start, end];

		let height = 25 + (this.props.hasXAxis ? 20 : 0);
		//if (isMobile) {
		//	height /= 1.5;
		//}

		return <ThemeContext.Consumer>
			{(theme) => <Row className="mbottom5" style={{ height: height }} onMouseLeave={() => { this.props.onHover(undefined) }}>
				<ChartCanvas metaId={this.props.metaId} ratio={ratio} width={width} height={height}
					metaSubscribe={this.props.chartContext.metaSubscribe}
					triggerMetaEvent={this.props.chartContext.triggerMetaEvent}
					useCrossHairStyleCursor={false}
					type="hybrid"
					zoomEvent={false}
					margin={{ left: 0, right: 60, top: 0, bottom: this.props.hasXAxis ? 20 : 0 }}
					seriesName=""
					xScale={xScale}
					xAccessor={xAccessor}
					displayXAccessor={displayXAccessor}
					xExtents={xExtents}
					data={data}>
					<Chart id={0} yExtents={[0, 1]} padding={1}>
						{this.props.hasXAxis && <XAxis
							axisAt="bottom"
							orient="bottom"
							zoomEnabled={false}
							fontFamily="Open Sans"
							fontSize={12}
							ticks={6} innerTickSize={-1000} tickStrokeOpacity={0.2}
							stroke={theme.cssVar('--bg-main')}
							tickStroke={theme.cssVar('--color-alt')} />}
						<BarSeries
							fill={d => d.values[this.props.token.dataIndex][3] > 0
								? theme.cssVar('--color-main')
								: d.values[this.props.token.dataIndex][1] > 0
									? theme.cssVar('--color-alt') : theme.cssVar('--bg-main-alt')}
							opacity={1}
							width={4}
							key={0}
							yAccessor={d => (1 / 1) * 0.9}
						/>
						<ClickCallback
							onMouseMove={this.onMouseEvent}
						//onMouseDown={(moreProps, e) => { console.log("onMouseDown", moreProps, e); }}
						//onClick={(moreProps, e) => { console.log("onClick", moreProps, e); }}
						//onDoubleClick={(moreProps, e) => { console.log("onDoubleClick", moreProps, e); }}
						//onContextMenu={(moreProps, e) => { console.log("onContextMenu", moreProps, e); }}
						//onPan={(moreProps, e) => { console.log("onPan", moreProps, e); }}
						//onPanEnd={(moreProps, e) => { console.log("onPanEnd", moreProps, e); }}
						/>
					</Chart>
				</ChartCanvas>
			</Row >}</ThemeContext.Consumer>
	}
}

const BooleanChartItemSized = fitWidth(BooleanChartItem);

export default BooleanChartItemSized;