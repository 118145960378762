import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { FunctionCall, LineModel, IndicatorToken, IToken } from './EditorModels';
import { IndicatorsCache } from '/src/services/indicatorsCache';

interface Props {
	token: IndicatorToken
}

const TokenText: React.FunctionComponent<Props> = (props) => {
	const functionInfo = IndicatorsCache.getIndicator(props.token.functionName);
	if (functionInfo === undefined) {
		return <div>function not found: {JSON.stringify(props.token)}</div>
	}
	return <Row>
		{props.token.parameters.map((parameter, index) => {
			if (Array.isArray(parameter) && parameter[0] === "Const") {
				return [
					functionInfo.parameters[index] && functionInfo.parameters[index].prefix &&
					<div key={"prefix" + index} className="mleft4">{functionInfo.parameters[index].prefix}</div>,
					<div key={"value" + index}  className="mleft4" style={{ color: "#555555" }}>{parameter[1]}</div>]
			}

			return [
				functionInfo.parameters[index] && functionInfo.parameters[index].prefix &&
				<div key={"prefix" + index} className="mleft4">{functionInfo.parameters[index].prefix}</div>,
				<div key={"value" + index} className="mleft4">{parameter}</div>
			]
		}
		)}
	</Row>
}

export default TokenText;