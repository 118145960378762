import * as React from "react";
import { Column, Row } from '/src/components/Layout'
import { AllocationStats } from "/src/services/trading"
import PositionsPanel from "./PositionsPanel";
import InstrumentSearchPanel from "./InstrumentSearchPanel";
import { ISelectable, TradeRuleToken, PanelButtons, IToken, TokenType, InstrumentToken, IndicatorToken, EditingCallbacks, MovingCallbacks } from '../EditorModels'
import InstrumentPanel from "./InstrumentPanel";
import IndicatorPanel from "./IndicatorPanel";
import TradeRulePanel from "./TradeRulePanel";
import IndicatorSearchPanel from "./IndicatorSearchPanel";
import { IndicatorModel, SignalDirection, StrategyState } from '/src/services/strategies'
import TradePanel from "./TradePanel";
import InvestPanel from "./InvestPanel";
import { Dialog } from "/node_modules/@headlessui/react/dist/index";
import { Position } from "/generated/cicada-client";

export enum PanelType {
	Common,
	Default,
	NewInstrument,
	NewIndicator,
	NewTradeRule,
	Trade,
	Invest,
	Trades
}

interface Props {
	type: PanelType,
	selected: ISelectable,
	positions: Position[],
	morePositions: boolean,
	stats: AllocationStats,
	instrumentOptions: InstrumentToken[],
	allOptions: IToken[],
	hasTradeRules: boolean,
	hasInstruments: boolean,
	isOwnStrategy: boolean,
	strategyState: StrategyState,
	editingCallbacks: EditingCallbacks,
	movingCallbacks: MovingCallbacks,
	onPanelClosed: () => void,
	onBacktest: () => void,
	onTradeSelected: (trade: Position) => void,
	onInvesting: () => void,
	onStopInvesting: () => void,
	onInvest: (amount: number) => void,
	isInvested: boolean,
	onStrategyDelete: () => void,
	onLoadMorePositions: () => void,
	onSignalForced: (direction: SignalDirection) => void
}

const SidePanel: React.FunctionComponent<Props> = (p) => {
	const selectedType = p.selected && (p.selected as IToken).type;

	const handleScroll = event => {
		const loaderHeight = 80;
		const reachedEnd = Math.abs(event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight) < loaderHeight;
		console.log({reachedEnd})
		if (!reachedEnd) {
			return;
		}
		if (p.type === PanelType.Common || (p.type === PanelType.Default && selectedType === undefined)) { //positions are shown
			p.onLoadMorePositions();
		}
	};

	return <Dialog open={
		p.type === PanelType.NewInstrument ||
		p.type === PanelType.NewIndicator ||
		(p.type === PanelType.Default && selectedType === TokenType.TradeRule)
	} onClose={p.onPanelClosed} >
		<Dialog.Panel className="bg-main" style={{
			boxShadow: "0 20px 20px rgba(0,0,0,.25)",
			border: "3px solid #585f70",
			borderRadius: 15,
			zIndex: 6,
			position: "fixed",
			left: "50%",
			top: "50%",
			transform: "translate(-50%, -50%)",
			width: 700,
			height: "50vh",
			overflowY: "auto",
			overflowX: "hidden"
		}}>
			{p.type === PanelType.Invest
				&& <InvestPanel onPanelClosed={p.onPanelClosed} onInvest={p.onInvest} />}
			{p.type === PanelType.Trade
				&& <TradePanel trade={p.selected as Position} onPanelClosed={p.onPanelClosed} />}
			{p.type === PanelType.Common
				&& <PositionsPanel
					isOwnStrategy={p.isOwnStrategy}
					isInvested={p.isInvested}
					type={PanelButtons.Common}
					onBacktest={p.onBacktest}
					strategyState={p.strategyState}
					positions={p.positions}
					morePositions={p.morePositions}
					onInstrumentAdding={p.editingCallbacks.onInstrumentAdding}
					onIndicatorAdding={p.editingCallbacks.onIndicatorAdding}
					onTradeRuleAdding={p.editingCallbacks.onTradeRuleAdding}
					hasTradeRules={p.hasTradeRules}
					hasInstruments={p.hasInstruments}
					onTradeSelected={p.onTradeSelected}
					onInvesting={p.onInvesting}
					onStopInvesting={p.onStopInvesting}
					onDelete={p.onStrategyDelete}
					stats={p.stats} />}
			{p.type === PanelType.Default && selectedType === undefined
				&& <PositionsPanel
					isOwnStrategy={p.isOwnStrategy}
					isInvested={p.isInvested}
					type={PanelButtons.Editing}
					onBacktest={p.onBacktest}
					strategyState={p.strategyState}
					positions={p.positions}
					morePositions={p.morePositions}
					onInstrumentAdding={p.editingCallbacks.onInstrumentAdding}
					onIndicatorAdding={p.editingCallbacks.onIndicatorAdding}
					onTradeRuleAdding={p.editingCallbacks.onTradeRuleAdding}
					hasTradeRules={p.hasTradeRules}
					hasInstruments={p.hasInstruments}
					onTradeSelected={p.onTradeSelected}
					onInvesting={p.onInvesting}
					onStopInvesting={p.onStopInvesting}
					onDelete={p.onStrategyDelete}
					stats={p.stats} />}
			{p.type === PanelType.Default && selectedType === TokenType.TradeRule
				&& <TradeRulePanel
					tradeRule={p.selected && (p.selected as TradeRuleToken)}
					instrumentOptions={p.instrumentOptions}
					movingCallbacks={p.movingCallbacks}
					onSignalForced={p.onSignalForced}
					onUpdated={p.editingCallbacks.onTradeRuleUpdated}
					allOptions={p.allOptions} />}

			{p.type === PanelType.NewInstrument
				&& <InstrumentSearchPanel
					options={p.allOptions}
					onInstrumentAdded={p.editingCallbacks.onInstrumentAdded}
					onInstrumentUpdated={p.editingCallbacks.onInstrumentUpdated}
					selected={p.selected && (p.selected as InstrumentToken)}
					onClose={p.onPanelClosed} />}

			{p.type === PanelType.NewIndicator
				&& <IndicatorSearchPanel
					options={p.allOptions}
					onIndicatorSelected={p.editingCallbacks.onIndicatorSelected}
					onIndicatorUpdated={p.editingCallbacks.onInstrumentUpdated}
					selected={p.selected && (p.selected as IndicatorToken)}
					onClose={p.onPanelClosed} />}

			{p.type === PanelType.NewTradeRule
				&& <div>trade rule not selected: should not be a case</div>}
		</Dialog.Panel>
	</Dialog >
}

export default React.memo(SidePanel);